<div class="impressum container">
    <h1>Impressum</h1>
    <pre>
    TC Grün-Weiß Amorbach 01 e.V.
    Kirchzeller Str.
    63916 Amorbach

    E-Mail: Vorstand@TC-Amorbach.de


    Gemeinschaftlich vertretungsbefugt:
    1. Vorsitzender: Heidi Jach
    Schloßplatz 1
    63916 Amorbach
    Telefon: +49 (0)9373 / 8550
    E-Mail: <u>Vorstand@TC-Amorbach.de</u>


    Das Impressum gilt für: <a href="https://www.tc-amorbach.de" target="_blank">http://www.tc-amorbach.de</a>

    Registergericht: Amtsgericht Aschaffenburg
    Registernummer: VR 20134

    Verantwortliche i.S.d. § 55 Abs. 2 RStV: Heidi Jach, Schloßplatz 1, 63916 Amorbach
    </pre>
</div>