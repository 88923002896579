<h1>Mitgliedschaft</h1>
<p>
  Willkommen bei unserem Verein! Unsere Mitgliedschaftspreise sind fair und
  transparent, um jedem die Möglichkeit zu geben, Teil unserer Gemeinschaft zu
  werden.
</p>

<table class="table table-bordered">
  <tbody>
    <tr>
      <th scope="row">Kinder & Jugendliche (&lt;14 Jahre)</th>
      <td>40 Euro</td>
    </tr>
    <tr>
      <th scope="row">Jugendliche (14-18 Jahre)</th>
      <td>50 Euro</td>
    </tr>
    <tr>
      <th scope="row">1 Erwachsener</th>
      <td>120 Euro</td>
    </tr>
    <tr>
      <th scope="row">2 Erwachsene</th>
      <td>165 Euro</td>
    </tr>
    <tr>
      <th scope="row">Familie</th>
      <td>185 Euro</td>
    </tr>
  </tbody>
</table>
<p>(keine Aufnahmegebüren)</p>

<style>
  table {
    margin-bottom: 0;
  }
  th,
  td {
    text-align: left;
    width: 50%;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }

  @media (min-width: 1300px) {
    table {
      font-size: 160%;
    }
  }
</style>
