<script>
  import Carousel from "../components/carousel.svelte";
  import Verein from "../components/verein.svelte";
  import News from "../components/news.svelte";
  import Mitgliedschaft from "../components/mitgliedschaft.svelte";
</script>

<main>
  <div class="welcome">
    <h1>Willkommen beim TC Amorbach</h1>
    <p>
      Herzlich willkommen auf der Website des TC Amorbach! Wir freuen uns, dass
      Sie den Weg zu uns gefunden haben. Unser Tennisclub bietet eine Vielzahl
      von Aktivitäten und Veranstaltungen für Tennisbegeisterte jeden Alters und
      jeder Spielstärke. Schauen Sie sich um und erfahren Sie mehr über unsere
      Angebote und unseren Verein.
    </p>
  </div>
  <hr />
  <div class="carousel">
    <Carousel />
    <hr />
  </div>
  <div class="sponsors">
    <h2>Unsere Sponsoren</h2>
    <p>Wir danken unseren Sponsoren für ihre großzügige Unterstützung.</p>
    <div class="sponsor-images">
      <img src="/img/merk.jpg" alt="MERK logo" />
      <img src="/img/laeuner.png" alt="Läuner logo" />
    </div>
  </div>
  <hr />
  <div class="news">
    <News />
  </div>
  <hr />
  <div class="verein">
    <Verein />
  </div>
  <hr />
  <div class="mitgliedschaft">
    <Mitgliedschaft />
  </div>
  <hr />
  <iframe
    title="map"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3179.405409017353!2d9.215841!3d49.6373821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797ffc5f40a94ed%3A0x41c067376eff7da9!2sKirchzeller%20Str.%202%2C%2063916%20Amorbach!5e1!3m2!1sde!2sde!4v1728749584728!5m2!1sde!2sde"
    width="600"
    height="450"
    style="border:0;"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
</main>

<style>
  hr {
    width: 100%;
    margin: 20px 0;
    border: 3px solid black;
  }
  main {
    margin: 1% 2%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* Welcome */
  .welcome {
    text-align: center;
  }
  .welcome h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  .welcome p {
    font-size: 1.5rem;
    margin: 0 20%;
    margin-bottom: 0;
  }

  /* Sponsors */
  .sponsors {
    margin: 1% 2%;
    margin-top: 10px;
  }
  .sponsor-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Verein */
  .verein, .mitgliedschaft {
    margin: 1% 2%;
    margin-top: 10px;
    width: 100%;
  }

  /* Media Queries */
  @media (max-width: 1300px) {
    /* Welcome */
    .welcome {
      padding: 5px;
      margin-bottom: 0;
    }
    .welcome h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    .welcome p {
      font-size: medium;
      margin: 0;
    }

    /* Sponsors */
    .sponsors {
      margin-bottom: 0;
    }
    .sponsor-images {
      flex-direction: column;
      background-color: none;
      margin-top: 0;
    }
    .sponsor-images img {
      width: 90%;
      margin: 10px 0;
    }

    iframe {
      width: 100%;
      height: 300px;
    }
  }
  @media (min-width: 1300px) {
    .carousel {
      display: none;
    }
  }

  /* interne Hilfsklassen */
  .light {
    background-color: rgba(245, 245, 245, 0.815);
  }
  .dark {
    background-color: #777777a9;
  }
</style>
