<footer>
  <div>
    <p>TC Grün-Weiß Amorbach 1901 e.V.</p>
    <a href="/#/contact">Kontakt</a>
    <div class="legal">
      <a href="/#/satzung">Satzung</a>
      <a href="/#/impressum">Impressum</a>
    </div>
    </div>
  </footer>

  <style>
    footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bcf413a9;
    padding: 20px;
    margin-top: 20px;
  }

  footer div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  footer div p {
    margin: 0;
  }

  footer div a {
    margin-left: 10px;
    color: white;
    background-color: black;
    text-decoration: none;
  }
</style>
