<div class="news">
  <h1>Aktuelles</h1>
  <p>Hier sind aktuelle Informationen.</p>

  <hr />

  <div class="n n-1">
    <div class="text">
      <h2>Headline</h2>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi maiores
        minus, quas sit cum hic alias sapiente voluptatibus. Ipsum voluptate,
        iusto, aliquid rem quam sapiente ab commodi adipisci libero optio
        laudantium quas beatae ut tempora officia, vel vitae maiores neque?
      </p>
    </div>
    <img src="/img/Foto_Fest.jpg" alt="Foto_Fest" />
  </div>
</div>

<style>
  .news h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .n {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
  }
  .n img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 777px) {
    h1 {
      font-size: 1.5rem;
    }
    .n img {
      width: 50%;
      height: auto;
    }
  }
</style>
